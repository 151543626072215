import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import { Box, Button, ButtonGroup, Paper, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Intro } from '../../domain/intro'
import { deleteIntroAPI } from '../../apis/intro/intro-apis'

interface Props {
  intro: Intro
  onClose: (shouldReload: boolean) => void
}

export const IntrosDelete = ({ intro, onClose }: Props) => {
  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    setLoading(true)

    deleteIntroAPI(intro.id)
      .then(() => {
        onClose(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal open>
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <Paper sx={{ width: '50%', padding: '20px' }}>
          <Box height="100%" display="flex" flexDirection="column" gap={3}>
            <Typography variant="h5">
              Do you really want to delete <strong>{intro.id}</strong>?
            </Typography>

            <Box textAlign="right">
              <ButtonGroup>
                <Button onClick={() => onClose(false)}>Cancel</Button>
                <LoadingButton
                  variant="contained"
                  color="error"
                  loading={loading}
                  onClick={() => handleSubmit()}
                >
                  Submit
                </LoadingButton>
              </ButtonGroup>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  )
}
