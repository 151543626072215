import { apiClient } from '../apiClient'
import { Intro, IntroCreate, IntroOrderUpdate } from '../../domain/intro'
import { getDownloadURL, getStorage, ref } from 'firebase/storage'

export const getIntrosAPI = async (cityId: number): Promise<Intro[]> => {
  const intros = await apiClient
    .get<Intro[]>(`/station-service/dashboard/cities/${cityId}/intros`)
    .then((r) => r.data)

  const storage = getStorage()
  const pathPromises = intros.map((intro) =>
    getDownloadURL(ref(storage, intro.assetPath)).catch(() => '')
  )
  const paths = await Promise.all(pathPromises)

  paths.forEach((path, i) => {
    intros[i].assetPath = path
  })

  return intros.sort((a, b) => a.orderNum - b.orderNum)
}

export const createIntroAPI = (cityId: number, dto: IntroCreate): Promise<Intro[]> => {
  const formData = new FormData()
  formData.set('bgColor', dto.bgColor)
  formData.set('controlsColor', dto.controlsColor)
  formData.set('asset', dto.asset)
  formData.set('showSkip', String(dto.showSkip))

  return apiClient
    .post(`/station-service/dashboard/cities/${cityId}/intros`, formData)
    .then((r) => r.data)
}

export const updateIntroAPI = (id: number, dto: Partial<IntroCreate>): Promise<Intro[]> => {
  const formData = new FormData()
  if (!!dto.bgColor) {
    formData.set('bgColor', dto.bgColor)
  }
  if (!!dto.controlsColor) {
    formData.set('controlsColor', dto.controlsColor)
  }
  if (!!dto.asset) {
    formData.set('asset', dto.asset)
  }
  if (dto.showSkip !== undefined) {
    formData.set('showSkip', String(dto.showSkip))
  }

  return apiClient.patch(`/station-service/dashboard/intros/${id}`, formData).then((r) => r.data)
}

export const updateIntrosOrderAPI = (cityId: number, dto: IntroOrderUpdate): Promise<void> => {
  return apiClient
    .patch(`/station-service/dashboard/cities/${cityId}/intros/order`, dto)
    .then((r) => r.data)
}

export const deleteIntroAPI = (id: number): Promise<void> => {
  return apiClient.delete(`/station-service/dashboard/intros/${id}`).then((r) => r.data)
}
