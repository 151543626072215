import React from 'react'
import './styles.scss'
import IconButton from '@mui/material/IconButton'
import FilterListIcon from '@mui/icons-material/FilterList'
import CloudDownloadIcon from '@mui/icons-material/CloudDownloadOutlined'
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownloadOutlined'
import CloudUploadIcon from '@mui/icons-material/CloudUploadOutlined'
import { strings } from '../../../I18n'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import InputBase from '@mui/material/InputBase'
import AddBoxIcon from '@mui/icons-material/AddBox'
import CloseIcon from '@mui/icons-material/Close'
import { isMobile } from 'react-device-detect'
import RefreshIcon from '@mui/icons-material/Refresh'
import useStyles from './styles'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import Tooltip from '@mui/material/Tooltip'

function SearchBlockWithRefreshBtn(props) {
  const {
    title,
    searchValue,
    onChange,
    pressFilter,
    addAction,
    clear,
    filterProps,
    removeFilter,
    reloadData,
    downloadCoupon,
    searchDataOptions,
    searchFilter,
    setSearchFilter,
    onExport,
    onImport,
  } = props

  const classes = useStyles()
  const animatedComponents = makeAnimated()
  return (
    <div>
      <div className={'dashboardTitleContainer'}>
        <p className={'dashboardTitle'}>{title}</p>
        <div className={'searchBlockContainer'}>
          <Paper component="form" className={classes.root}>
            {searchDataOptions && (
              <Select
                isMulti={false}
                name="colors"
                options={searchDataOptions}
                components={animatedComponents}
                closeMenuOnSelect={false}
                placeholder={'Search By'}
                value={searchFilter}
                isClearable={true}
                onChange={(e) => setSearchFilter(e)}
                styles={{
                  container: (base) => ({
                    ...base,
                    padding: 5,
                    border: 0,
                    boxShadow: 'none',
                  }),
                  control: (base) => ({
                    ...base,
                    padding: 5,
                    border: 0,
                  }),
                }}
              />
            )}

            <InputBase
              autoFocus={!isMobile}
              className={classes.input}
              placeholder={strings('placeholders.search')}
              value={searchValue}
              onChange={onChange}
              size={'medium'}
            />
            <IconButton className={classes.iconButton} aria-label="clear" onClick={clear}>
              <CloseIcon />
            </IconButton>
            {(pressFilter || addAction) && (
              <Divider className={classes.divider} orientation="vertical" />
            )}
            {pressFilter && (
              <IconButton className={classes.iconButton} aria-label="filter" onClick={pressFilter}>
                <FilterListIcon />
              </IconButton>
            )}
            {onExport && (
              <IconButton className={classes.iconButton} onClick={onExport}>
                <Tooltip title="Export Vehicles">
                  <CloudDownloadIcon />
                </Tooltip>
              </IconButton>
            )}
            {onImport && (
              <IconButton className={classes.iconButton} onClick={onImport}>
                <Tooltip title="Import Vehicles">
                  <CloudUploadIcon />
                </Tooltip>
              </IconButton>
            )}
            {addAction && (
              <IconButton className={classes.iconButton} aria-label="add" onClick={addAction}>
                <AddBoxIcon />
              </IconButton>
            )}
            {downloadCoupon && (
              <IconButton
                className={classes.iconButton}
                aria-label="download"
                onClick={downloadCoupon}
              >
                <SimCardDownloadIcon />
              </IconButton>
            )}
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton onClick={reloadData}>
              <RefreshIcon />
            </IconButton>
          </Paper>
        </div>
      </div>
      {filterProps?.length > 0 &&
        filterProps.map(
          (filter, index) =>
            !!filter.filter && (
              <div key={index} className={'filterDescriptionBloc'}>
                <InputBase
                  className={classes.filter}
                  readOnly={true}
                  placeholder={strings('placeholders.search')}
                  value={`${filter.filterBy
                    .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
                    .toLowerCase()}: ${filter.filter.replace(/_/g, ' ')}`}
                  variant={'outlined'}
                />
                <IconButton
                  className={classes.iconButton}
                  aria-label="clear"
                  onClick={() => removeFilter(index)}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            )
        )}
    </div>
  )
}

export default SearchBlockWithRefreshBtn
