import { Box, Button, ButtonGroup, FormControlLabel, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getWarningsAPI } from '../../apis/warning/warning-apis'
import { Warning, WarningShowAt } from '../../domain/warning'
import { WarningsAdd } from './WarningsAdd'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import { WarningsItem } from './WarningsItem'
import { WarningsDelete } from './WarningsDelete'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'

export const WarningsContainer = () => {
  const { code } = useParams()
  const { citiesList } = useSelector((s: any) => s.cities)
  const city = useMemo(() => citiesList.find((c: any) => c.code === code), [code])
  const [beforeWarnings, setBeforeWarnings] = useState<Warning[]>([])
  const [afterWarnings, setAfterWarnings] = useState<Warning[]>([])
  const [isAddOpen, setIsAddOpen] = useState(false)
  const [warningToDelete, setWarningToDelete] = useState<Warning | null>(null)
  const [showAt, setShowAt] = useState<WarningShowAt>(WarningShowAt.JOURNEY_START)
  const warnings = showAt === WarningShowAt.JOURNEY_START ? beforeWarnings : afterWarnings

  const fetchAndSetIntros = () => {
    if (city) {
      getWarningsAPI(city.id).then((result) => {
        setBeforeWarnings(result.before)
        setAfterWarnings(result.after)
      })
    }
  }

  const handleClose = (shouldReload: boolean) => {
    setIsAddOpen(false)
    setWarningToDelete(null)

    if (shouldReload) {
      fetchAndSetIntros()
    }
  }

  useEffect(() => {
    fetchAndSetIntros()
  }, [])

  return (
    <Box sx={{ padding: '20px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 4 }}>
        <Typography variant="h4">Rentals warnings: {city.name}</Typography>

        <Box sx={{ display: 'flex', gap: 5 }}>
          <RadioGroup
            value={showAt}
            onChange={(e) => setShowAt(e.target.value as WarningShowAt)}
            row
          >
            <FormControlLabel
              value={WarningShowAt.JOURNEY_START}
              control={<Radio />}
              label="At Journey Start"
            />
            <FormControlLabel
              value={WarningShowAt.JOURNEY_END}
              control={<Radio />}
              label="At Journey End"
            />
          </RadioGroup>

          <ButtonGroup variant="contained">
            <Button size="small" onClick={() => setIsAddOpen(true)}>
              Add
            </Button>
          </ButtonGroup>
        </Box>
      </Box>

      <Table sx={{ width: '100%' }}>
        <TableBody>
          {warnings.map((warning) => {
            return (
              <WarningsItem
                key={warning.id}
                warning={warning}
                onDelete={() => setWarningToDelete(warning)}
              />
            )
          })}
        </TableBody>
      </Table>

      {isAddOpen && <WarningsAdd cityId={city.id} showAt={showAt} onClose={handleClose} />}
      {!!warningToDelete && <WarningsDelete warning={warningToDelete} onClose={handleClose} />}
    </Box>
  )
}
