export enum WarningType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  CHECKBOX = 'CHECKBOX',
}

export enum WarningShowAt {
  JOURNEY_START = 'JOURNEY_START',
  JOURNEY_END = 'JOURNEY_END',
}

export interface Warning {
  id: number
  dateCreated: string
  dateUpdated: string
  orderNum: number
  content: string
  type: WarningType
  showAt: WarningShowAt
  cityId: number
  stationId?: number
}

export interface WarningListResponse {
  all: Warning[]
  before: Warning[]
  after: Warning[]
}

export interface WarningCreate {
  orderNum: number
  text?: string
  asset?: File
  type: WarningType
  showAt: WarningShowAt
  cityId: number
  stationId?: number
}
