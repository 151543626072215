import React from 'react'
import Modal from '@mui/material/Modal'
import { Autocomplete, Box, Button, ButtonGroup, Paper, Stack, Typography } from '@mui/material'
import TextField from '@mui/material/TextField/TextField'
import { LoadingButton } from '@mui/lab'
import CloudUploadIcon from '@mui/icons-material/CloudUploadOutlined'
import { WarningCreate, WarningShowAt, WarningType } from '../../domain/warning'
import { createWarningAPI } from '../../apis/warning/warning-apis'

const TYPE_OPTIONS: Array<{ value: WarningType; label: string }> = [
  {
    value: WarningType.TEXT,
    label: 'Text',
  },
  {
    value: WarningType.IMAGE,
    label: 'Image',
  },
  {
    value: WarningType.CHECKBOX,
    label: 'Checkbox',
  },
]
const DEFAULT_TYPE = TYPE_OPTIONS[0]

interface Props {
  cityId: number
  showAt: WarningShowAt
  onClose: (shouldReload: boolean) => void
}

export const WarningsAdd = ({ cityId, showAt, onClose }: Props) => {
  const [type, setType] = React.useState(DEFAULT_TYPE)
  const [orderNum, setOrderNum] = React.useState(0)
  const [asset, setAsset] = React.useState<File | undefined>(undefined)
  const [text, setText] = React.useState<string>('')
  const [loading, setLoading] = React.useState(false)
  const isImageType = type.value === WarningType.IMAGE
  const disabled = orderNum === undefined || (isImageType ? !asset : !text)

  const createWarning = () => {
    setLoading(true)

    const dto: WarningCreate = {
      orderNum,
      type: type.value,
      showAt,
      cityId,
    }

    if (isImageType) {
      dto.asset = asset
    } else {
      dto.text = text
    }

    createWarningAPI(cityId, dto)
      .then(() => {
        onClose(true)
      })
      .catch(() => setLoading(false))
  }

  return (
    <Modal open>
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <Paper sx={{ width: '50%', padding: '20px' }}>
          <Box height="100%" display="flex" flexDirection="column" gap={3}>
            <Typography variant="h5">Add Warning</Typography>

            <Stack spacing={2}>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <TextField
                  fullWidth
                  type="number"
                  label="Order"
                  value={orderNum}
                  onChange={(e) => setOrderNum(parseInt(e.target.value))}
                />

                <Autocomplete
                  fullWidth
                  value={type}
                  onChange={(_, value) => setType(value || DEFAULT_TYPE)}
                  options={TYPE_OPTIONS}
                  getOptionLabel={(o) => o.label}
                  getOptionKey={(o) => o.value}
                  renderInput={(params) => <TextField {...params} label="Type" />}
                />
              </Box>

              {isImageType && (
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  {!!asset ? asset.name : 'Upload asset'}
                  <input
                    type="file"
                    onChange={(event) =>
                      setAsset(event.target.files ? event.target.files[0] : undefined)
                    }
                    style={{ display: 'none' }}
                  />
                </Button>
              )}

              {!isImageType && (
                <TextField
                  fullWidth
                  multiline={true}
                  rows={5}
                  label="Text"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
              )}
            </Stack>

            <Box textAlign="right">
              <ButtonGroup>
                <Button onClick={() => onClose(false)}>Cancel</Button>
                <LoadingButton
                  variant="contained"
                  loading={loading}
                  disabled={disabled}
                  onClick={() => createWarning()}
                >
                  Submit
                </LoadingButton>
              </ButtonGroup>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  )
}
