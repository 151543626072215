import React from 'react'
import { Intro } from '../../domain/intro'
import { IntrosFrames } from './IntrosFrames'
import Paper from '@mui/material/Paper'
import { Box, Grid, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

interface Props {
  intro: Intro
  slidesSize: number
  orderIndex: number
  onEdit: () => void
  onDelete: () => void
  onDrop: (e: { target: number; before: number }) => void
}

export const IntrosItem = ({ intro, slidesSize, orderIndex, onEdit, onDelete, onDrop }: Props) => {
  const [isDragging, setIsDragging] = React.useState(false)
  const isDraggingRef = React.useRef(false)

  return (
    <Grid item={true} md={4} xs={4}>
      <Paper
        elevation={2}
        sx={{
          padding: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
        draggable
        onDragStart={(e) => {
          e.dataTransfer.setData('text/plain', String(orderIndex))
          isDraggingRef.current = true
        }}
        onDragOver={(e) => {
          if (!isDraggingRef.current) {
            e.preventDefault()
            setIsDragging(true)
            isDraggingRef.current = false
          }
        }}
        onDragEnd={(e) => {
          if (!isDraggingRef.current) {
            e.preventDefault()
            setIsDragging(false)
            isDraggingRef.current = false
          }
        }}
        onDragLeave={(e) => {
          if (!isDraggingRef.current) {
            e.preventDefault()
            setIsDragging(false)
            isDraggingRef.current = false
          }
        }}
        onDrop={(e) => {
          e.preventDefault()
          setIsDragging(false)
          isDraggingRef.current = false
          onDrop({ before: orderIndex, target: Number(e.dataTransfer.getData('text/plain')) })
        }}
      >
        <IntrosFrames
          bgColor={intro.bgColor}
          controlsColor={intro.controlsColor}
          assetPath={intro.assetPath}
          showSkip={intro.showSkip}
          slidesSize={slidesSize}
          scale={0.5}
        />

        <SpeedDial
          ariaLabel="Actions"
          sx={{ position: 'absolute', top: 16, right: 16 }}
          direction="down"
          icon={<SpeedDialIcon />}
        >
          <SpeedDialAction
            icon={<EditIcon color="primary" />}
            tooltipTitle="Edit"
            onClick={() => onEdit()}
          />
          <SpeedDialAction
            icon={<DeleteIcon color="error" />}
            tooltipTitle="Delete"
            onClick={() => onDelete()}
          />
        </SpeedDial>

        <Box
          sx={(t) => ({
            width: '2px',
            height: '100%',
            position: 'absolute',
            left: -3,
            top: 0,
            background: t.palette.primary.main,
            display: isDragging ? 'block' : 'none',
          })}
        ></Box>
      </Paper>
    </Grid>
  )
}
