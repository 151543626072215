import React from 'react'
import { Warning, WarningType } from '../../domain/warning'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell/TableCell'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { ButtonGroup } from '@mui/material'

interface Props {
  warning: Warning
  onDelete: () => void
}

interface ContentProps {
  type: WarningType
  content: string
}

const Content = ({ type, content }: ContentProps) => {
  switch (type) {
    case WarningType.IMAGE:
      return <img src={content} alt="Warning" style={{ maxWidth: 200 }} />
    case WarningType.TEXT:
    case WarningType.CHECKBOX:
    default:
      return <>{content}</>
  }
}

export const WarningsItem = ({ warning, onDelete }: Props) => {
  return (
    <TableRow>
      <TableCell>{warning.orderNum}</TableCell>
      <TableCell>{warning.type}</TableCell>
      <TableCell>
        <Content type={warning.type} content={warning.content} />
      </TableCell>
      <TableCell>
        <ButtonGroup size="small">
          <IconButton color="error" onClick={() => onDelete()}>
            <DeleteIcon />
          </IconButton>
        </ButtonGroup>
      </TableCell>
    </TableRow>
  )
}
