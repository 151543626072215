import { Box, Button, ButtonGroup, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import EditNoteIcon from '@mui/icons-material/EditNote'
import { ServiceAccount } from '../../domain/service-account'
import { getServiceAccounts } from '../../apis/service-account/service-account-apis'
import { ServiceAccountsAddEdit } from './ServiceAccountsAddEdit'
import { ServiceAccountsDelete } from './ServiceAccountsDelete'
import { useDispatch, useSelector } from 'react-redux'
import { getWhiteLabel } from '../../actions/cities'
import { ServiceAccountsUpdateKeys } from './ServiceAccountsUpdateKeys'

export const ServiceAccountsContainer = () => {
  const { whiteLabels = [], citiesList = [] } = useSelector((s: any) => s.cities)
  const dispatch = useDispatch()
  const [accounts, setAccounts] = useState<ServiceAccount[]>([])
  const [isAddOpen, setIsAddOpen] = useState(false)
  const [accountToEdit, setAccountToEdit] = useState<ServiceAccount | null>(null)
  const [keyToEdit, setKeyToEdit] = useState<string | null>(null)
  const [accountToDelete, setAccountToDelete] = useState<ServiceAccount | null>(null)

  const fetchAndSetAccounts = () => {
    getServiceAccounts().then((data) => setAccounts(data))
  }

  const handleClose = (shouldUpdate: boolean) => {
    if (shouldUpdate) {
      fetchAndSetAccounts()
    }

    setIsAddOpen(false)
    setAccountToEdit(null)
    setKeyToEdit(null)
    setAccountToDelete(null)
  }

  const citiesMap = useMemo(() => {
    return citiesList.reduce((acc: any, city: any) => {
      acc[city.id] = city.name
      return acc
    }, {})
  }, [citiesList])

  const whiteLabelsMap = useMemo(() => {
    return whiteLabels.reduce((acc: any, wl: any) => {
      acc[wl.id] = wl.name
      return acc
    }, {})
  }, [whiteLabels])

  useEffect(() => {
    fetchAndSetAccounts()
    dispatch(getWhiteLabel())
  }, [])

  return (
    <Box sx={{ padding: '20px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <Typography variant="h4">Service Accounts</Typography>

        <ButtonGroup variant="contained">
          <Button size="small" onClick={() => setIsAddOpen(true)}>
            Add
          </Button>
        </ButtonGroup>
      </Box>

      <Table sx={{ width: '100%' }}>
        <TableHead sx={{ fontWeight: 700 }}>
          <TableRow>
            <TableCell>
              <strong>Key</strong>
            </TableCell>
            <TableCell>
              <strong>Currency</strong>
            </TableCell>
            <TableCell>
              <strong>Min Amount</strong>
            </TableCell>
            <TableCell>
              <strong>White Label</strong>
            </TableCell>
            <TableCell>
              <strong>Cities</strong>
            </TableCell>
            <TableCell>
              <strong>Payment methods</strong>
            </TableCell>
            <TableCell sx={{ width: 0 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accounts.map((sa) => (
            <TableRow key={sa.key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>{sa.key}</TableCell>
              <TableCell>{sa.currency}</TableCell>
              <TableCell>{sa.minAmount}</TableCell>
              <TableCell>
                {(sa.whiteLabelIds || []).map((id) => whiteLabelsMap[id]).join(', ')}
              </TableCell>
              <TableCell>{(sa.cityIds || []).map((id) => citiesMap[id]).join(', ')}</TableCell>
              <TableCell>{sa.methodTypes.join(', ')}</TableCell>
              <TableCell>
                <ButtonGroup size="small">
                  <IconButton color="primary" onClick={() => setAccountToEdit(sa)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="primary" onClick={() => setKeyToEdit(sa.key)}>
                    <EditNoteIcon />
                  </IconButton>
                  <IconButton color="error" onClick={() => setAccountToDelete(sa)}>
                    <DeleteIcon />
                  </IconButton>
                </ButtonGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {isAddOpen && <ServiceAccountsAddEdit onClose={() => handleClose(true)} />}
      {!!accountToEdit && (
        <ServiceAccountsAddEdit
          account={accountToEdit}
          onClose={(shouldReload) => handleClose(shouldReload)}
        />
      )}
      {!!keyToEdit && (
        <ServiceAccountsUpdateKeys
          keyToEdit={keyToEdit}
          onClose={(shouldReload) => handleClose(shouldReload)}
        />
      )}
      {!!accountToDelete && (
        <ServiceAccountsDelete
          account={accountToDelete}
          onClose={(shouldReload) => handleClose(shouldReload)}
        />
      )}
    </Box>
  )
}
