import React from 'react'
import Modal from '@mui/material/Modal'
import { Box, Button, ButtonGroup, Checkbox, Paper, Stack, Typography } from '@mui/material'
import TextField from '@mui/material/TextField/TextField'
import { LoadingButton } from '@mui/lab'
import CloudUploadIcon from '@mui/icons-material/CloudUploadOutlined'
import { IntrosFrames } from './IntrosFrames'
import { Intro, IntroCreate } from '../../domain/intro'
import { createIntroAPI, updateIntroAPI } from '../../apis/intro/intro-apis'

interface Props {
  cityId: number
  onClose: (shouldReload: boolean) => void
  intro?: Intro
}

interface FormData {
  bgColor: string
}

export const IntrosAddEdit = ({ cityId, onClose, intro }: Props) => {
  const [bgColor, setBgColor] = React.useState(intro ? intro.bgColor : '#000000')
  const [asset, setAsset] = React.useState<File | undefined>(undefined)
  const [controlsColor, setControlsColor] = React.useState(intro ? intro.controlsColor : '#000000')
  const [showSkip, setShowSkip] = React.useState(!!intro?.showSkip)
  const [loading, setLoading] = React.useState(false)
  const disabled = intro ? false : !asset

  const createIntro = () => {
    if (!asset) return

    setLoading(true)

    const dto: IntroCreate = {
      bgColor,
      controlsColor,
      asset,
      showSkip,
    }

    createIntroAPI(cityId, dto)
      .then(() => {
        onClose(true)
      })
      .catch(() => setLoading(false))
  }

  const updateIntro = () => {
    if (!intro) return

    const dto: Partial<IntroCreate> = {
      bgColor,
      controlsColor,
      asset,
      showSkip,
    }

    setLoading(true)

    updateIntroAPI(intro.id, dto)
      .then(() => {
        onClose(true)
      })
      .catch(() => setLoading(false))
  }

  const handleSubmit = () => {
    intro ? updateIntro() : createIntro()
  }

  return (
    <Modal open>
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <Paper sx={{ width: '80%', padding: '20px' }}>
          <Box height="100%" display="flex" flexDirection="column" gap={3}>
            <Typography variant="h5">Add Intro Screen</Typography>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box width={'48%'}>
                <Stack spacing={2}>
                  <Stack spacing={2}>
                    <Typography variant="h6">Colors</Typography>

                    <TextField
                      fullWidth
                      type="color"
                      label="Background color"
                      value={bgColor}
                      onChange={(e) => setBgColor(e.target.value)}
                    />

                    <TextField
                      fullWidth
                      type="color"
                      label="Controls color"
                      value={controlsColor}
                      onChange={(e) => setControlsColor(e.target.value)}
                    />
                  </Stack>

                  <Stack spacing={2}>
                    <Typography variant="h6">Asset</Typography>

                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                    >
                      {!!asset ? asset.name : 'Upload asset'}
                      <input
                        type="file"
                        onChange={(event) =>
                          setAsset(event.target.files ? event.target.files[0] : undefined)
                        }
                        style={{ display: 'none' }}
                      />
                    </Button>
                  </Stack>

                  <Stack spacing={2}>
                    <Typography variant="h6">Skip</Typography>

                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Checkbox
                        checked={showSkip}
                        onChange={(e) => setShowSkip(e.target.checked)}
                        sx={{ padding: 0 }}
                      />
                      <Typography variant="h6">Show Skip</Typography>
                    </Box>
                  </Stack>
                </Stack>
              </Box>

              <Box width={'48%'}>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                  <IntrosFrames
                    assetPath={asset ? URL.createObjectURL(asset) : intro ? intro.assetPath : ''}
                    bgColor={bgColor}
                    controlsColor={controlsColor}
                    showSkip={showSkip}
                    slidesSize={1}
                  />
                </Box>
              </Box>
            </Box>

            <Box textAlign="right">
              <ButtonGroup>
                <Button onClick={() => onClose(false)}>Cancel</Button>
                <LoadingButton
                  variant="contained"
                  loading={loading}
                  disabled={disabled}
                  onClick={() => handleSubmit()}
                >
                  Submit
                </LoadingButton>
              </ButtonGroup>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  )
}
