/* eslint-disable */
import React, { useEffect, useState } from 'react'
import './styles.scss'
import { connect, useDispatch } from 'react-redux'
import SearchBlockWithRefreshBtn from '../../components/table/searchBlockWithRefreshBtn'
import { strings } from '../../I18n'
import RentalsItem from '../../components/items/rentalsItem'
import {
  clearRental,
  clearRentalsData,
  clearRentalsForExcel,
  finishRent,
  getFinallyDataOfRental,
  getRentalById,
  getRentals,
  getRentalsForExcel,
  getRentalsStatuses,
  rentalsDockList,
  rentalsUserList,
  rentalsVehiclesList,
  updateRental,
} from '../../actions/rentals'
import { Box, IconButton, Tooltip } from '@mui/material'
import RentalModal from '../../components/modal/rentalModal'
import RentalFilter from '../../components/dialog/rentalFilter'
import {
  getLinkedStationsByCity,
  getStationsByCity,
  getStationsOnMap,
} from '../../actions/stations'
import Loader from '../../components/loader'
import { emptyPassedParams, setSearchValue } from '../../actions/screen'
import { CSVDownload } from 'react-csv'
import GetAppIcon from '@mui/icons-material/GetApp'
import Moment from 'moment'
import { useDebounce, useShowToast } from '../../hooks'
import ImagePreviewModal from '../../components/modal/ImagePreviewModal'
import InputDialogWithMap from '../../components/dialog/inputDialogWithMap'
import { reformatFilter, reformatSearch } from '../../utils/utils'
import { Link, useSearchParams } from 'react-router-dom'
import { DASHBOARD_RENTALS_GALLERY } from '../../constants/routes'

function RentalsContainer(props) {
  const {
    orderProps,
    changePage,
    searchValue,
    changeSearch,
    clearSearchValue,
    rentalByIdSuccess,
    finishRent,
    rentalsVehiclesList,
    getStationsByCity,
    rentalsDockList,
    rentalsUserList,
    updateRentalSuccess,
    clearRental,
    getRentals,
    filterProps,
    postFinishRentalSuccess,
    pageSize,
    rentalsStatusesSuccess,
    getRentalsStatuses,
    clearRentalsData,
    rentalsStatuses,
    citiesList,
    getFinallyDataOfRental,
    finallyRentalData,
    getFinallyRentalData,
    getRentalsSuccess,
    savedCity,
    getLinkedStationsByCity,
    passedParams,
    emptyPassedParams,
    getRentalById,
    currentPageValue,
    rentalsForExcel,
    getRentalsForExcelSuccess,
    clearRentalsForExcel,
    getStationsOnMap,
    orderValue,
  } = props

  const headers = [
    { label: strings('descriptions.rentalId'), key: 'id' },
    { label: strings('descriptions.userName'), key: 'userName' },
    { label: strings('descriptions.bikeName'), key: 'vehicleName' },
    { label: strings('descriptions.transactionLink'), key: 'paymentId' },
    { label: strings('descriptions.startStation'), key: 'startStationName' },
    { label: strings('descriptions.startDock'), key: 'startStationCode' },
    { label: strings('descriptions.startDate'), key: 'startTime' },
    { label: strings('descriptions.endStation'), key: 'endStationName' },
    { label: strings('descriptions.endDock'), key: 'endDockCode' },
    { label: strings('descriptions.endDate'), key: 'endTime' },
    { label: strings('descriptions.duration'), key: 'duration' },
    { label: strings('descriptions.cityProject'), key: 'cityId' },
    { label: strings('descriptions.vehicleCode'), key: 'vehicleCode' },
    { label: strings('descriptions.lockCode'), key: 'lockCode' },
    { label: strings('descriptions.status'), key: 'status' },
  ]

  const searchDataOptions = [
    { value: 'rentId', label: 'rentId' },
    { value: 'username', label: 'username' },
    { value: 'userId', label: 'userId' },
    { value: 'bikeName', label: 'bikeName' },
    { value: 'startStationName', label: 'startStationName' },
    { value: 'endStationName', label: 'endStationName' },
    { value: 'startDockCode', label: 'startDockCode' },
    { value: 'endDockCode', label: 'endDockCode' },
    { value: 'duration', label: 'duration' },
  ]

  const [finishRentDialogVisibility, setFinishRentDialogVisibility] = React.useState(false)
  const [filterVisible, setFilterVisible] = React.useState(false)
  const [rentals, setRentals] = React.useState(props.rentals)
  const [startDownload, setStartDownload] = useState(false)
  const [filterValues, setFilterValues] = React.useState([])
  const [selectedRentalUser, setSelectedRentalUser] = useState()
  const [currentRentStartTime, setCurrentRentStartTime] = useState(null)
  const [imagePreviewModal, setImagePreviewModal] = useState(false)
  const [previewImageUrls, setPreviewImageUrls] = useState([])
  const [searchFilter, setSearchFilter] = useState()

  const debouncedSearchTerm = useDebounce(searchValue, 1000)
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()

  useEffect(() => {
    if (searchParams.has('search')) {
      dispatch(setSearchValue(searchParams.get('search')))
      searchParams.delete('search')
      setSearchParams(searchParams)
    }

    const filter = searchDataOptions.find((item) => searchParams.has(item.value))
    if (filter) {
      setSearchFilter(filter)
      dispatch(setSearchValue(searchParams.get(filter.value)))
      searchParams.delete(filter.value)
      setSearchParams(searchParams)
    }
  }, [])

  useEffect(() => {
    setRentals(props.rentals)
  }, [props.rentals])

  useEffect(() => {
    if (rentalByIdSuccess) {
      rentalsVehiclesList()
      getLinkedStationsByCity(null)
      getStationsByCity(null)
      rentalsDockList(null)
      rentalsUserList(selectedRentalUser)
      getRentalsStatuses()
    }
  }, [
    rentalByIdSuccess,
    rentalsVehiclesList,
    getStationsByCity,
    rentalsDockList,
    rentalsUserList,
    getLinkedStationsByCity,
    getRentalsStatuses,
    selectedRentalUser,
  ])

  useEffect(() => {
    if (!!filterProps?.filterValue) {
      setFilterValues(filterProps?.filterValue || [])
    }
  }, [filterProps])

  useEffect(() => {
    if (passedParams) {
      getRentalById(passedParams)
      emptyPassedParams()
    }
  }, [passedParams, getRentalById, emptyPassedParams])

  useEffect(() => {
    if (updateRentalSuccess) {
      showToast('Rent updated.', 'success')
      clearRental()
      reloadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateRentalSuccess, clearRental, savedCity])

  useEffect(() => {
    if (postFinishRentalSuccess) {
      clearRental()
      reloadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postFinishRentalSuccess, clearRental, getRentals, savedCity])

  useEffect(() => {
    if (getFinallyRentalData) {
      showRentDialog()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFinallyRentalData])

  useEffect(() => {
    if (getRentalsForExcelSuccess) {
      setStartDownload(true)
      setTimeout(() => {
        clearRentalsForExcel()
      }, 500)
    }
  }, [getRentalsForExcelSuccess, setStartDownload, clearRentalsForExcel])

  useEffect(() => {
    if (rentalsStatusesSuccess && !rentalByIdSuccess) {
      setFilterVisible(true)
    }
  }, [rentalsStatusesSuccess, rentalByIdSuccess])

  const hideRentDialog = React.useCallback(() => {
    setFinishRentDialogVisibility(false)
    clearRentalsData()
  }, [setFinishRentDialogVisibility, clearRentalsData])

  const showRentDialog = React.useCallback(() => {
    setFinishRentDialogVisibility(true)
  }, [setFinishRentDialogVisibility])

  const showToast = useShowToast()

  const finishRentAction = React.useCallback(
    (id, data) => {
      if (Moment(data.endTime).isBefore(currentRentStartTime)) {
        showToast('End time cannot be before start time', 'error')
      } else {
        hideRentDialog()
        finishRent(id, data)
      }
    },
    [hideRentDialog, finishRent, currentRentStartTime]
  )

  const startFinishingAction = React.useCallback(
    (rental) => {
      getStationsByCity(rental?.cityId)
      getStationsOnMap(
        ['Stations'],
        ['CHARGE', 'VIRTUAL'],
        ['ONLINE', 'OFFLINE'],
        [
          'Renting unlocked',
          'Renting locked',
          'Not renting charging',
          'Not renting locked',
          'Not renting unlocked',
          'Low battery or offline',
        ],
        rental?.cityId,
        ''
      )
      rentalsDockList(rental?.cityId)
      getFinallyDataOfRental(rental.id)
      setCurrentRentStartTime(rental.startTime)
    },
    [getStationsByCity, rentalsDockList, getFinallyDataOfRental]
  )

  const handleChangePage = React.useCallback(
    (event, newPage) => {
      changePage(newPage)
    },
    [changePage]
  )

  const handleDownloadCsv = () => {
    setStartDownload(true)
    setTimeout(() => {
      setStartDownload(false)
    }, 500)
  }

  const renderRentalsList = React.useCallback(
    () => (
      <>
        <Tooltip title={strings('descriptions.downloadRentals')}>
          <Box
            sx={{
              padding: '10px 0',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <IconButton size="small" onClick={handleDownloadCsv} target="_blank">
              <GetAppIcon />
            </IconButton>

            <Link to={DASHBOARD_RENTALS_GALLERY}>Rentals Gallery</Link>
          </Box>
        </Tooltip>
        {startDownload && (
          <CSVDownload data={rentals} headers={headers} target="_blank" filename="rentals.csv" />
        )}
        <RentalsItem
          finishRentAction={startFinishingAction}
          rentals={rentals}
          rentalsResult={props.rentalsResult}
          handleChangePage={handleChangePage}
          orderProps={orderProps}
          citiesList={props.citiesList}
          setSelectedRentalUser={setSelectedRentalUser}
          setImagePreviewModal={setImagePreviewModal}
          setPreviewImageUrls={setPreviewImageUrls}
        />
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      startFinishingAction,
      rentals,
      props,
      handleChangePage,
      orderProps,
      startDownload,
      rentalsForExcel,
      headers,
    ][
      // eslint-disable-next-line
      (startFinishingAction, rentalsForExcel, props, handleChangePage, orderProps)
    ]
  )

  const pressFilter = () => {
    getRentalsStatuses()
  }

  const hideFilter = () => {
    setFilterVisible(false)
    clearRentalsData()
  }

  const changeSelectorValue = React.useCallback(
    (filter, filterBy) => {
      if (filterValues.filter((item) => item.filterBy === filterBy).length > 0) {
        if (!filter.length) {
          setFilterValues(filterValues?.filter((item) => item.filterBy !== filterBy))
        } else {
          setFilterValues(
            filterValues.map((item) => {
              if (item.filterBy === filterBy) {
                return { filter, filterBy }
              }
              return item
            })
          )
        }
      } else {
        setFilterValues([...filterValues, { filter, filterBy }])
      }
    },
    [filterValues, setFilterValues]
  )

  const removeFilter = React.useCallback(
    (indexValue) => {
      filterProps.setFilterValue(
        filterProps.filterValue.filter((item, index) => index !== indexValue)
      )
    },
    [filterProps]
  )

  const applyFilter = React.useCallback(() => {
    setFilterVisible(false)
    clearRentalsData()
    filterProps.setFilterValue(filterValues)
  }, [filterValues, filterProps, clearRentalsData])

  const reloadData = React.useCallback(() => {
    getRentals(
      currentPageValue,
      reformatSearch(searchFilter, debouncedSearchTerm),
      orderValue,
      reformatFilter(filterProps.filterValue) + (savedCity ? '&' + savedCity : ''),
      pageSize
    )
  }, [
    currentPageValue,
    debouncedSearchTerm,
    pageSize,
    savedCity,
    getRentals,
    searchFilter,
    filterProps.filterValue,
    orderValue,
  ])

  useEffect(() => {
    reloadData()
  }, [
    currentPageValue,
    debouncedSearchTerm,
    pageSize,
    savedCity,
    getRentals,
    searchFilter,
    filterProps.filterValue,
  ])
  useEffect(() => {
    if (orderProps?.orderBy && orderProps.order) {
      reloadData()
    }
  }, [orderProps.order, orderProps.orderBy])
  const content = () => (
    <>
      <SearchBlockWithRefreshBtn
        title={strings('title.rentals')}
        searchValue={searchValue}
        reloadData={reloadData}
        onChange={changeSearch}
        clear={clearSearchValue}
        pressFilter={pressFilter}
        filterProps={filterProps.filterValue}
        removeFilter={removeFilter}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        searchDataOptions={searchDataOptions}
      />
      {rentals?.length > 0 ? (
        <div className={'dashboardList'}>{renderRentalsList()}</div>
      ) : (
        <p className={'emptyResultText'}>{strings('message.noRentals')}</p>
      )}
      <RentalFilter
        agree={applyFilter}
        disagree={hideFilter}
        visible={filterVisible}
        hideFilter={hideFilter}
        changeSelectorValue={changeSelectorValue}
        citiesList={citiesList}
        filterProps={filterValues}
        statuses={rentalsStatuses}
      />
      <InputDialogWithMap
        title={strings('message.finishTrip')}
        visibility={finishRentDialogVisibility}
        hideDialog={hideRentDialog}
        positiveAction={finishRentAction}
        negativeAction={hideRentDialog}
        stationsList={props.stationsByCity}
        docksList={props.rentalDocks}
        rentalData={finallyRentalData}
        disablePast={false}
      />

      <RentalModal
        clearRental={clearRental}
        visible={
          !!(
            props.rentalVehiclesSuccess &&
            props.getStationsByCitySuccess &&
            props.rentalDocksSuccess &&
            props.rentalUsersSuccess &&
            props.rentalsStatusesSuccess
          )
        }
        rental={props.rental}
        vehicles={props.rentalVehicles}
        stations={props.stationsByCity}
        docks={props.rentalDocks}
        users={props.rentalUsers}
        updateRental={props.updateRental}
        statuses={rentalsStatuses}
      />
      <ImagePreviewModal
        previewImageUrls={previewImageUrls}
        visible={imagePreviewModal}
        setImagePreviewModal={setImagePreviewModal}
        setPreviewImageUrls={setPreviewImageUrls}
      />
    </>
  )

  const loading = () => <Loader />

  return <div className={'dashboardContent'}>{getRentalsSuccess ? content() : loading()}</div>
}

const mapStateToProps = ({
  rentals: {
    rentals,
    rentalsResult,
    getRentalsSuccess,
    rentalByIdSuccess,
    rental,
    rentalVehiclesSuccess,
    rentalDocksSuccess,
    rentalVehicles,
    rentalDocks,
    rentalUsers,
    rentalUsersSuccess,
    updateRentalSuccess,
    rentalsStatusesSuccess,
    rentalsStatuses,
    finallyRentalData,
    getFinallyRentalData,
    postFinishRentalSuccess,
    rentalsForExcel,
    getRentalsForExcelSuccess,
  },
  cities: { citiesList, savedCity },
  screen: { pageSize, passedParams, currentPageValue },
  stations: { getStationsByCitySuccess, stationsByCity },
}) => ({
  rentals,
  rentalsForExcel,
  rentalsResult,
  getRentalsSuccess,
  getRentalsForExcelSuccess,
  rentalByIdSuccess,
  rental,
  rentalVehiclesSuccess,
  rentalDocksSuccess,
  rentalVehicles,
  rentalDocks,
  rentalUsers,
  rentalUsersSuccess,
  updateRentalSuccess,
  citiesList,
  savedCity,
  pageSize,
  rentalsStatusesSuccess,
  rentalsStatuses,
  finallyRentalData,
  getFinallyRentalData,
  postFinishRentalSuccess,
  getStationsByCitySuccess,
  stationsByCity,
  passedParams,
  currentPageValue,
})

const mapDispatchToProps = {
  clearRental,
  finishRent,
  rentalsDockList,
  rentalsVehiclesList,
  rentalsUserList,
  updateRental,
  getRentals,
  getRentalsStatuses,
  clearRentalsData,
  getFinallyDataOfRental,
  getStationsByCity,
  getLinkedStationsByCity,
  emptyPassedParams,
  getRentalById,
  getRentalsForExcel,
  clearRentalsForExcel,
  getStationsOnMap,
}

export default connect(mapStateToProps, mapDispatchToProps)(RentalsContainer)
