/* eslint-disable */
import React, { useEffect, useState } from 'react'
import './styles.scss'
import BikeItemV2 from '../../components/items/bikeItem/BikeItemV2'
import { connect } from 'react-redux'
import { strings } from '../../I18n'
import {
  clearBike,
  clearBikesData,
  clearHonjiBikesData,
  getBikeBrands,
  getBikeId,
  getBikeLockStatuses,
  getBikeStatus,
  getVehicle,
  updateBike,
} from '../../actions/vehicle'
import { getVehicleByCode } from '../../actions/rentals'

import { clearStation, getLinkedStationsByCity, getStationsByCity } from '../../actions/stations'
import { emptyPassedParams, setSearchValue } from '../../actions/screen'
import { useCity, useDebounce, useShowToast } from '../../hooks'
import BikeModal from '../../components/modal/bikeModal'
import BikeFilter from '../../components/dialog/bikeFilter'
import Loader from '../../components/loader'
import SearchBlockWithRefreshBtn from '../../components/table/searchBlockWithRefreshBtn'
import { reformatFilter, reformatSearch } from '../../utils/utils'
import { useParams } from 'react-router-dom'
import { ExportBox } from '../../components/exportBox/ExportBox'
import { ImportBox } from '../../components/importBox/ImportBox'

function BikeContainer(props) {
  const {
    orderProps,
    changePage,
    searchValue,
    changeSearch,
    clearSearchValue,
    clearBike,
    filterProps,
    getBikeStatus,
    getBikeBrands,
    getBikeLockStatuses,
    statuses,
    brands,
    lockStatuses,
    clearBikesData,
    getVehicleSuccess,
    savedCity,
    stationsByCity,
    getStationsByCity,
    linkedStations,
    getLinkedStationsByCity,
    bikeTypes,
    setSearchValue,
    currentPageValue,
    pageSize,
    orderValue,
  } = props
  const showToast = useShowToast()
  const [filterVisible, setFilterVisible] = useState(false)
  const [bikes, setBikes] = useState(props.bikes)
  const [filterValues, setFilterValues] = useState([])
  const [bikeModalVisible, setBikeModalVisible] = React.useState(false)
  const [searchFilter, setSearchFilter] = useState()
  const debouncedSearchTerm = useDebounce(searchValue, 500)
  const params = useParams()
  const [showExport, setShowExport] = useState(false)
  const [showImport, setShowImport] = useState(false)
  const { selectedCity } = useCity()

  const searchDataOptions = [
    { value: 'journeyId', label: 'journeyId' },
    { value: 'username', label: 'username' },
    { value: 'vehicleName', label: 'vehicleName' },
    { value: 'vehicleCode', label: 'vehicleCode' },
    { value: 'lockCode', label: 'lockCode' },
    { value: 'dockCode', label: 'dockCode' },
    // { value: 'vehicleType', label: 'vehicleType' },
    { value: 'stationName', label: 'stationName' },
  ]

  const showModal = () => setBikeModalVisible(true)
  const hideModal = () => setBikeModalVisible(false)
  const vehicleCode = params.id

  useEffect(() => {
    if (vehicleCode) {
      setSearchValue(vehicleCode)
    }
  }, [setSearchValue, vehicleCode])

  useEffect(() => {
    if (!!filterProps?.filterValue) {
      setFilterValues(filterProps?.filterValue || [])
    }
  }, [filterProps])

  useEffect(() => {
    setBikes(props.bikes)
  }, [props.bikes, props.updateBikeSuccess])

  useEffect(() => {
    props.getBikeByIdSuccess && showModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getBikeByIdSuccess, savedCity])

  useEffect(() => {
    if (props.updateBikeSuccess) {
      showToast('Success', 'success')
      clearBike()
      hideModal()
      reloadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.updateBikeSuccess, clearBike])

  useEffect(() => {
    if (props.lockHonjiSuccess) {
      showToast('Success', 'success')
      props.clearHonjiBikesData()
      reloadData()
    }
  }, [props.lockHonjiSuccess])
  useEffect(() => {
    if (props.unlockHonjiSuccess) {
      showToast('Success', 'success')
      props.clearHonjiBikesData()
      reloadData()
    }
  }, [props.unlockHonjiSuccess])

  const changeStatus = (bike, body) => {
    props.updateBike(bike.id, body)
  }

  const handleChangePage = (event, newPage) => {
    changePage(newPage)
  }

  const handleListUpdate = () => {
    setShowImport(false)
    reloadData()
  }

  const renderBikeList = () => {
    return (
      <BikeItemV2
        bikes={bikes}
        selectMenu={changeStatus}
        bikesResult={props.bikesResult}
        handleChangePage={handleChangePage}
        orderProps={orderProps}
        statuses={statuses}
        setFilters={filterProps.setFilterValue}
        onDelete={() => handleListUpdate()}
      />
    )
  }

  const pressFilter = () => {
    setFilterVisible(true)
    getBikeStatus()
    getBikeBrands()
    getBikeLockStatuses()
  }

  const hideFilter = () => {
    setFilterVisible(false)
    clearBikesData()
  }

  const changeSelectorValue = React.useCallback(
    (filter, filterBy) => {
      if (filterValues.filter((item) => item.filterBy === filterBy).length > 0) {
        if (!filter.length) {
          setFilterValues(filterValues?.filter((item) => item.filterBy !== filterBy))
        } else {
          setFilterValues(
            filterValues.map((item) => {
              if (item.filterBy === filterBy) {
                return { filter, filterBy }
              }
              return item
            })
          )
        }
      } else {
        setFilterValues([...filterValues, { filter, filterBy }])
      }
    },
    [filterValues, setFilterValues]
  )

  const removeFilter = React.useCallback(
    (indexValue) => {
      const filterValues = filterProps.filterValue.filter((item, index) => index !== indexValue)
      filterProps.setFilterValue(filterValues?.length > 0 ? filterValues : null)
      setFilterValues(filterValues)
    },
    [filterProps]
  )

  const applyFilter = React.useCallback(() => {
    setFilterVisible(false)
    clearBikesData()
    filterProps.setFilterValue(filterValues)
  }, [filterValues, filterProps, clearBikesData])

  const closeModal = () => {
    props.clearBike()
    hideModal()
  }

  const reloadData = () => {
    // props.getBikes()
    props.getVehicle(
      currentPageValue,
      reformatSearch(searchFilter, debouncedSearchTerm),
      orderValue,
      reformatFilter(filterProps.filterValue) + (savedCity ? '&' + savedCity : ''),
      pageSize
    )
  }

  useEffect(() => {
    reloadData()
  }, [
    currentPageValue,
    debouncedSearchTerm,
    pageSize,
    savedCity,
    searchFilter,
    filterProps.filterValue,
  ])
  useEffect(() => {
    if (orderProps?.orderBy && orderProps.order) {
      reloadData()
    }
  }, [orderProps.order, orderProps.orderBy])
  const content = () => (
    <>
      <SearchBlockWithRefreshBtn
        title={strings('title.bikes')}
        searchValue={searchValue}
        reloadData={reloadData}
        onChange={changeSearch}
        clear={clearSearchValue}
        pressFilter={pressFilter}
        filterProps={filterProps.filterValue}
        removeFilter={removeFilter}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        searchDataOptions={searchDataOptions}
        onExport={selectedCity ? () => setShowExport(true) : null}
        onImport={selectedCity ? () => setShowImport(true) : null}
      ></SearchBlockWithRefreshBtn>
      {bikes.length > 0 ? (
        <div className={'dashboardList'}>{renderBikeList()}</div>
      ) : (
        <p className={'emptyResultText'}>{strings('message.noBikes')}</p>
      )}
      <BikeModal
        visible={bikeModalVisible}
        clearBike={closeModal}
        bike={props.bike}
        statuses={props.statuses}
        updateBike={props.updateBike}
        cityNameList={props.citiesList}
        stations={stationsByCity}
        getStations={getStationsByCity}
        getLinkedStations={getLinkedStationsByCity}
        linkedStations={linkedStations}
        bikeTypes={bikeTypes}
      />
      <BikeFilter
        agree={applyFilter}
        disagree={hideFilter}
        visible={filterVisible}
        hideFilter={hideFilter}
        changeSelectorValue={changeSelectorValue}
        citiesList={props.citiesList}
        filterProps={filterValues}
        statuses={statuses}
        brands={brands}
        lockStatuses={lockStatuses}
      />
      {showExport && (
        <ExportBox
          onSuccess={() => setShowExport(false)}
          onError={() => setShowExport(false)}
          onClose={() => setShowExport(false)}
        />
      )}
      {showImport && (
        <ImportBox
          onSuccess={handleListUpdate}
          onError={() => setShowImport(false)}
          onClose={() => setShowImport(false)}
        />
      )}
    </>
  )

  const loading = () => <Loader />

  return <div className={'dashboardContent'}>{getVehicleSuccess ? content() : loading()}</div>
}

const mapStateToProps = ({
  vehicle: {
    bikes,
    bikesResult,
    updateBikeSuccess,
    getBikeByIdSuccess,
    bike,
    statuses,
    getLockStatusesSuccess,
    brands,
    lockStatuses,
    getVehicleSuccess,
    bikeTypes,
    lockHonjiSuccess,
    unlockHonjiSuccess,
  },
  cities: { citiesList, savedCity },
  stations: { stationsByCity, linkedStations, station, stationByIdSuccess },
  screen: { passedParams, currentPageValue, pageSize },
  rentals: { rentalBike, rentalVehiclesSuccess },
}) => ({
  bikes,
  bikesResult,
  updateBikeSuccess,
  citiesList,
  getBikeByIdSuccess,
  bike,
  statuses,
  brands,
  lockStatuses,
  savedCity,
  getLockStatusesSuccess,
  getVehicleSuccess,
  stationsByCity,
  linkedStations,
  station,
  stationByIdSuccess,
  bikeTypes,
  rentalBike,
  rentalVehiclesSuccess,
  lockHonjiSuccess,
  unlockHonjiSuccess,
  passedParams,
  currentPageValue,
  pageSize,
})

const mapDispatchToProps = {
  updateBike,
  clearBike,
  getBikeStatus,
  getBikeBrands,
  getBikeLockStatuses,
  clearBikesData,
  getStationsByCity,
  getLinkedStationsByCity,
  clearStation,
  getBikeId,
  emptyPassedParams,
  getVehicleByCode,
  setSearchValue,
  clearHonjiBikesData,
  getVehicle,
}

export default connect(mapStateToProps, mapDispatchToProps)(BikeContainer)
