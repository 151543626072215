import { Box, Button, ButtonGroup, Grid, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { IntrosAddEdit } from './IntrosAddEdit'
import { getIntrosAPI, updateIntrosOrderAPI } from '../../apis/intro/intro-apis'
import { Intro } from '../../domain/intro'
import { IntrosItem } from './IntrosItem'
import { IntrosDelete } from './IntrosDelete'
import { useShowToast } from '../../hooks'

export const IntrosContainer = () => {
  const showToast = useShowToast()
  const { code } = useParams()
  const { citiesList } = useSelector((s: any) => s.cities)
  const city = useMemo(() => citiesList.find((c: any) => c.code === code), [code])
  const [intros, setIntros] = useState<Intro[]>([])
  const [currIntros, setCurrIntros] = useState<Intro[]>([])
  const [isAddOpen, setIsAddOpen] = useState(false)
  const [introToDelete, setIntroToDelete] = useState<Intro | null>(null)
  const [introToEdit, setIntroToEdit] = useState<Intro | null>(null)

  const fetchAndSetIntros = () => {
    if (city) {
      getIntrosAPI(city.id).then((result) => {
        setIntros(result)
        setCurrIntros(result)
      })
    }
  }

  const updateIntrosOrder = () => {
    updateIntrosOrderAPI(city.id, { orderedIds: currIntros.map((intro) => intro.id) })
      .then(() => fetchAndSetIntros())
      .then(() => showToast('Intros order updated', 'success'))
      .catch(() => showToast('Failed to update intros order', 'error'))
  }

  const handleClose = (shouldReload: boolean) => {
    setIsAddOpen(false)
    setIntroToDelete(null)
    setIntroToEdit(null)

    if (shouldReload) {
      fetchAndSetIntros()
    }
  }

  const handleDrop = (target: number, before: number) => {
    const targetIntro = currIntros[target]
    const newCurrIntros = currIntros.filter((_, i) => i !== target)
    newCurrIntros.splice(before, 0, targetIntro)
    setCurrIntros(newCurrIntros)
  }

  const introsAreReordered = intros.some((intro, index) => intro.id !== currIntros[index].id)

  useEffect(() => {
    fetchAndSetIntros()
  }, [])

  return (
    <Box sx={{ padding: '20px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 4 }}>
        <Typography variant="h4">Intros of {city.name}</Typography>

        <ButtonGroup variant="contained">
          {introsAreReordered && (
            <Button size="small" onClick={() => updateIntrosOrder()}>
              Save this order
            </Button>
          )}
          <Button size="small" onClick={() => setIsAddOpen(true)}>
            Add
          </Button>
        </ButtonGroup>
      </Box>

      <Grid container={true} spacing={2}>
        {currIntros.map((intro, index) => {
          return (
            <IntrosItem
              key={intro.id}
              intro={intro}
              slidesSize={intros.length}
              orderIndex={index}
              onEdit={() => setIntroToEdit(intro)}
              onDelete={() => setIntroToDelete(intro)}
              onDrop={({ target, before }) => handleDrop(target, before)}
            />
          )
        })}
      </Grid>

      {isAddOpen && <IntrosAddEdit cityId={city.id} onClose={handleClose} />}
      {!!introToEdit && (
        <IntrosAddEdit cityId={city.id} onClose={handleClose} intro={introToEdit} />
      )}
      {!!introToDelete && <IntrosDelete intro={introToDelete} onClose={handleClose} />}
    </Box>
  )
}
