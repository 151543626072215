import React, { useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { appCheckSetup, onTokenIdChanged, userChangeListener } from './utils/firebase'
import { getAdminDetails, saveToken } from './actions/auth'
import * as routes from './constants/routes'
import DashboardScreen from './containers/dashboardScreen'
import { connect, useDispatch } from 'react-redux'
import { ToastProvider } from 'react-toast-notifications'
import NotFound from './containers/NotFoundContainer'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import Moment from 'moment'

import Loader from './components/loader'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import LoginScreen from './containers/loginScreen'
import ForgotPasswordScreen from './containers/forgotPasswordScreen'
import { FlagProvider } from '@unleash/proxy-client-react'
import { cityList } from './actions/cities'
import { useIsAdminAsset } from './hooks/user-hooks'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { InfoContainer } from './containers/info/InfoContainer'

const theme = createTheme()

const flagConfig = {
  url: `${process.env.REACT_APP_UNLEASH_URL}frontend`,
  clientKey: process.env.REACT_APP_UNLEASH_KEY,
  refreshInterval: 15,
  appName: 'lisa-dashboard',
}

function CustomRedirect({ children, redirectTo, showChildrenWhen }) {
  return showChildrenWhen ? children : <Navigate to={redirectTo} />
}

function App(props) {
  const [showApp, setShowApp] = useState(false)
  const dispatch = useDispatch()
  const isAdminAsset = useIsAdminAsset()

  React.useEffect(() => {
    appCheckSetup()

    userChangeListener(async (user) => {
      if (user) {
        await dispatch(getAdminDetails())
        await dispatch(cityList())
      }
      setShowApp(true)
    })

    if (!!props.user) {
      onTokenIdChanged(updateToken)
    }
    // return logoutFromFirebase
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateToken = (user) => {
    if (user) {
      user.getIdToken().then(props.saveToken)
    }
  }

  Moment.updateLocale('en', {
    week: {
      dow: 1,
    },
  })

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <FlagProvider config={flagConfig}>
        <ThemeProvider theme={theme}>
          {showApp ? (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <BrowserRouter>
                <ToastProvider>
                  <Routes>
                    <Route path={routes.INFO_SCREEN} element={<InfoContainer />} />
                    <Route
                      path={routes.LOGIN_SCREEN}
                      element={
                        <CustomRedirect
                          redirectTo={
                            isAdminAsset ? routes.ASSET_PROJECTS : routes.DASHBOARD_SCREEN
                          }
                          showChildrenWhen={!props.user}
                        >
                          <LoginScreen />
                        </CustomRedirect>
                      }
                    />
                    <Route
                      path={routes.FORGOT_PASSWORD_SCREEN}
                      element={
                        <CustomRedirect
                          redirectTo={
                            isAdminAsset ? routes.ASSET_PROJECTS : routes.DASHBOARD_SCREEN
                          }
                          showChildrenWhen={!props.user}
                        >
                          <ForgotPasswordScreen />
                        </CustomRedirect>
                      }
                    />
                    <Route
                      path={routes.DASHBOARD_SCREEN + '/*'}
                      element={
                        <CustomRedirect
                          redirectTo={routes.LOGIN_SCREEN}
                          showChildrenWhen={!!props.user}
                        >
                          <DashboardScreen />
                        </CustomRedirect>
                      }
                    />
                    <Route path={'*'} element={<NotFound />} />
                  </Routes>
                </ToastProvider>
              </BrowserRouter>
            </MuiPickersUtilsProvider>
          ) : (
            <Loader />
          )}
        </ThemeProvider>
      </FlagProvider>
    </LocalizationProvider>
  )
}

const mapStateToProps = ({ auth: { user, token }, stations: { station }, cities: { city } }) => ({
  user,
  station,
  city,
  token,
})
const mapDispatchToProps = {
  saveToken,
}
// self.FIREBASE_APPCHECK_DEBUG_TOKEN = true

export default connect(mapStateToProps, mapDispatchToProps)(App)
