import React from 'react'
import { Box, Typography } from '@mui/material'

interface Props {
  bgColor: string
  controlsColor: string
  assetPath: string
  showSkip: boolean
  slidesSize: number
  scale?: number
}

export const IntrosFrames = ({
  bgColor,
  controlsColor,
  assetPath,
  showSkip,
  slidesSize,
  scale,
}: Props) => {
  const controlsArr = new Array(slidesSize).fill(0)
  const width = 320 * (scale || 1)
  const height = 568 * (scale || 1)

  return (
    <Box
      sx={{
        width,
        height,
        position: 'relative',
        overflow: 'hidden',
        borderRadius: 5,
        border: '1px solid #ccc',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          background: bgColor,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {!!assetPath && (
          <img
            src={assetPath}
            alt="intro"
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
        )}
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: 5,
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          gap: 0.5,
        }}
      >
        {controlsArr.map((_, index) => {
          return (
            <Box
              key={index}
              sx={{
                width: 10 * (scale || 1),
                height: 10 * (scale || 1),
                borderRadius: '50%',
                background: controlsColor,
              }}
            ></Box>
          )
        })}
      </Box>

      <Box
        sx={{
          position: 'absolute',
          right: 15 * (scale || 1),
          top: 10 * (scale || 1),
          fontSize: 14 * (scale || 1),
          color: controlsColor,
          display: showSkip ? 'block' : 'none',
        }}
      >
        <Typography>Skip</Typography>
      </Box>
    </Box>
  )
}
