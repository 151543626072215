import { AlertColor, Box } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { Alert } from '@mui/lab'

export const InfoContainer = () => {
  const [params] = useSearchParams()
  const severity = (params.get('severity') || 'info') as AlertColor
  const message = params.get('message') || ''

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Alert severity={severity} variant="outlined" sx={{ width: '80%' }}>
        {message}
      </Alert>
    </Box>
  )
}
