import { apiClient } from '../apiClient'
import { getDownloadURL, getStorage, ref } from 'firebase/storage'
import {
  Warning,
  WarningCreate,
  WarningListResponse,
  WarningShowAt,
  WarningType,
} from '../../domain/warning'

export const getWarningsAPI = async (cityId: number): Promise<WarningListResponse> => {
  const warnings = await apiClient
    .get<Warning[]>(`/station-service/dashboard/cities/${cityId}/warnings`)
    .then((r) => r.data)

  const storage = getStorage()
  const contentPromises = warnings.map((warning) => {
    if (warning.type === WarningType.IMAGE) {
      return getDownloadURL(ref(storage, warning.content)).catch(() => '')
    } else {
      return warning.content
    }
  })
  const content = await Promise.all(contentPromises)

  content.forEach((content, i) => {
    warnings[i].content = content
  })

  warnings.sort((a, b) => a.orderNum - b.orderNum)

  return {
    all: warnings,
    before: warnings.filter((w) => w.showAt === WarningShowAt.JOURNEY_START),
    after: warnings.filter((w) => w.showAt === WarningShowAt.JOURNEY_END),
  }
}

export const createWarningAPI = (cityId: number, dto: WarningCreate): Promise<Warning[]> => {
  const formData = new FormData()
  formData.set('orderNum', String(dto.orderNum))
  formData.set('type', dto.type)
  formData.set('showAt', dto.showAt)
  formData.set('cityId', String(dto.cityId))

  if (dto.type === WarningType.IMAGE && dto.asset) {
    formData.set('asset', dto.asset)
  } else {
    formData.set('text', dto.text || '')
  }

  return apiClient
    .post(`/station-service/dashboard/cities/${cityId}/warnings`, formData)
    .then((r) => r.data)
}

export const deleteWarningAPI = (id: number): Promise<void> => {
  return apiClient.delete(`/station-service/dashboard/warnings/${id}`).then((r) => r.data)
}
